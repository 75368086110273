<template>
  <v-container>
    <h1>关羽</h1>
  </v-container>

</template>

<script>
export default {
  name: "Search"
}
</script>

<style scoped>

</style>